import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { findByType } from '@helpers';
import Label from '@components/Label';

import styles from './styles.module.scss';

const Select = ({
    placeholder,
    minimal,
    label,
    labelInline,
    labelHint,
    required,
    icon,
    value,
    fullWidth = true,
    border = true,
    name,
    onChange,
    options,
    children,
}) => {
    const minimalClass = minimal ? styles.selectMinimal : '';
    const fullWidthClass = fullWidth ? styles.selectFullWidth : '';
    const placeholderClass = !value ? styles.selectPlaceholder : '';
    const noBorderClass = !border ? styles.selectNoBorder : '';
    const inlineLabelClass = labelInline ? styles.selectLabelInline : '';
    const svgIcon = icon ? (
        <FontAwesomeIcon icon={icon} />
    ) : (
        <FontAwesomeIcon icon={'caret-down'} />
    );

    return (
        <div
            className={`${styles.select} ${minimalClass} ${fullWidthClass} ${placeholderClass} ${noBorderClass}`}
        >
            {label ? (
                <div className={`${styles.selectLabel} ${inlineLabelClass}`}>
                    <Label text={label} hint={labelHint} required={required} />
                </div>
            ) : null}

            <select name={name} onChange={onChange} value={value || ''}>
                {!value && (
                    <option value="" disabled>
                        {placeholder || 'Please choose an option...'}
                    </option>
                )}
                {findByType(children, Option)}
            </select>
            {svgIcon}
        </div>
    );
};

const Option = ({ value, label }) => <option value={value}>{label}</option>;

Select.Option = Option;
Option.displayName = 'Option';

export default Select;
