import React, { Fragment } from 'react';

import Alert from '@components/Alert';
import Card from '@components/Card';
import { Divider } from '@components/Grid';
import { graphql, useStaticQuery } from 'gatsby';
import Button from '@components/Button';

const Order = ({ products, countries, addresses, email, shipping, subtotal, total }) => {
    const {
        merlinWebsite: {
            store: { currency },
        },
    } = useStaticQuery(
        graphql`
            query {
                merlinWebsite {
                    store {
                        currency {
                            symbol
                            code
                        }
                    }
                }
            }
        `
    );

    return (
        <Fragment>
            <Alert
                type="success"
                title="Order successful"
                message={`Expect an email receipt at ${email} shortly, thank you for shopping with us!`}
            />
            <Divider />

            <h1>Your order</h1>

            <Card>
                <Card.Content>
                    <Card.List title="Items">
                        {products.map((item) => (
                            <Card.List.Item
                                key={item._id}
                                label={`${item.quantity} x ${item.name}`}
                                value={`${currency.symbol}${(item.total / 100).toFixed(2)}`}
                            />
                        ))}
                    </Card.List>
                    <Card.List divider={false} title="Shipping Details">
                        <Card.List.Item
                            id="shipping_name"
                            label="Name"
                            value={addresses?.shipping?.name}
                        />
                        <Card.List.Item
                            id="shipping_email"
                            label="Email"
                            value={addresses?.shipping?.email}
                        />
                        <Card.List.Item id="shipping_address">
                            <p>{`${addresses?.shipping?.addressOne ?? ''}${
                                addresses?.shipping?.addressTwo
                                    ? `, ${addresses?.shipping?.addressTwo}`
                                    : ''
                            },`}</p>
                            <p>{`${addresses?.shipping?.city}, ${addresses?.shipping?.state}, ${addresses?.shipping?.zip},`}</p>
                            <p>
                                {
                                    countries.find(
                                        ({ code }) => code === addresses?.shipping?.country
                                    )?.name
                                }
                            </p>
                        </Card.List.Item>
                    </Card.List>
                    <Card.List divider={false} title="Billing Details">
                        {addresses?.billing?.sameAsShipping ? (
                            <Fragment>
                                <Card.List.Item
                                    id="billing_name"
                                    label="Name"
                                    value={addresses?.shipping?.name}
                                />
                                <Card.List.Item
                                    id="billing_email"
                                    label="Email"
                                    value={
                                        addresses?.shipping?.sameAsShipping
                                            ? addresses?.shipping?.email
                                            : addresses?.shipping?.email
                                    }
                                />
                                <Card.List.Item id="billing_address">
                                    <p>{`${addresses?.shipping?.addressOne ?? ''}${
                                        addresses?.shipping?.addressTwo
                                            ? `, ${addresses?.shipping?.addressTwo}`
                                            : ''
                                    },`}</p>
                                    <p>{`${addresses?.shipping?.city}, ${addresses?.shipping?.state}, ${addresses?.shipping?.zip},`}</p>
                                    <p>
                                        {
                                            countries.find(
                                                ({ code }) => code === addresses?.shipping?.country
                                            )?.name
                                        }
                                    </p>
                                </Card.List.Item>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <Card.List.Item
                                    id="billing_name"
                                    label="Name"
                                    value={addresses?.billing?.name}
                                />
                                <Card.List.Item
                                    id="billing_email"
                                    label="Email"
                                    value={
                                        addresses?.billing?.sameAsShipping
                                            ? addresses?.billing?.email
                                            : addresses?.billing?.email
                                    }
                                />
                                <Card.List.Item id="billing_address">
                                    <p>{`${addresses?.billing?.addressOne ?? ''}${
                                        addresses?.billing?.addressTwo
                                            ? `, ${addresses?.billing?.addressTwo}`
                                            : ''
                                    },`}</p>
                                    <p>{`${addresses?.billing?.city}, ${addresses?.billing?.state}, ${addresses?.billing?.zip},`}</p>
                                    <p>
                                        {
                                            countries.find(
                                                ({ code }) => code === addresses?.billing?.country
                                            )?.name
                                        }
                                    </p>
                                </Card.List.Item>
                            </Fragment>
                        )}
                    </Card.List>
                    {!!shipping && (
                        <Card.List title="Shipping Service">
                            <Card.List.Item id="shipping_service">
                                <p>{shipping?.serviceName}</p>
                            </Card.List.Item>
                        </Card.List>
                    )}
                    <Card.List divider={false}>
                        <Card.List.Item
                            label="Subtotal"
                            value={`${currency.symbol}${parseFloat(subtotal).toFixed(2)}`}
                        />
                        <Card.List.Item
                            label="Delivery"
                            value={`${currency.symbol}${(
                                shipping?.shipmentCost ??
                                0 + shipping?.otherCost ??
                                0
                            ).toFixed(2)}`}
                        />
                        <Card.List.Item
                            label="Total"
                            bold
                            value={`${currency.symbol}${parseFloat(total / 100).toFixed(2)} ${
                                currency.code
                            }`}
                        />
                    </Card.List>
                </Card.Content>
            </Card>

            <Divider />

            <Button text="Go home" link="/" />
        </Fragment>
    );
};

export default Order;
