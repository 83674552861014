import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { findByType } from '@helpers';

import styles from './styles.module.scss';
import Label from '../Label';

const Checkboxes = ({ inline, label, onChange, children }) => {
    const handleChange = ({ event, id, checked, data }) => {
        const activeCheckboxes = Checkboxes.map(child => {
            let isChecked = id === child.props.id ? checked : child.props.checked;

            // allow consumer to plumb in array of checked ids and if this id matches set to checked automatically for them
            if (Array.isArray(isChecked)) {
                isChecked = isChecked.indexOf(child.props.id) > -1;
            }

            // if was not passed a boolean, but instead the checked id, we compare if this checked choice is the same as the id
            if (isChecked !== true && isChecked !== false) {
                isChecked = child.props.id === isChecked;
            }

            return isChecked ? child.props.id : null;
        }).filter(x => !!x);

        onChange && onChange({ event, id, checked, active: activeCheckboxes, data });
    };

    const Checkboxes = findByType(children, Checkbox, false, { onChange: handleChange });

    return (
        <div className={`${styles.checkboxes} ${inline ? styles.checkboxesInline : ''}`}>
            {label && <label>{label}</label>}
            {Checkboxes}
        </div>
    );
};

const Checkbox = ({ id, value, label, checked, onChange, onClick, ...data }) => {
    const handleClick = event => {
        onChange && onChange({ event, checked: !checked, id, data: { value, label, ...data } });
        onClick && onClick({ id, value, label, ...data });
    };

    // allow consumer to plumb in array of checked ids and if this id matches set to checked automatically for them
    if (Array.isArray(checked)) {
        checked = checked.indexOf(id) > -1;
    }

    // if was not passed a boolean, but instead the checked id, we compare if this checked choice is the same as the id
    if (checked !== true && checked !== false) {
        checked = id === checked;
    }

    return (
        <div
            className={`${styles.checkbox} ${checked ? styles.checkboxChecked : ''}`}
            onClick={handleClick}
        >
            <div className={styles.checkboxToggle} tabIndex={0}>
                {checked ? <FontAwesomeIcon icon={'check'} /> : null}
            </div>

            <div className={styles.checkboxLabel}>
                <Label text={label} onClick={handleClick} />
            </div>
        </div>
    );
};

Checkboxes.Checkbox = Checkbox;
Checkbox.displayName = 'Checkbox';

Checkboxes.defaultProps = {
    inline: false,
};

Checkboxes.propTypes = {
    inline: PropTypes.bool,
    onChange: PropTypes.func,
    label: PropTypes.bool,
};

export default Checkboxes;
