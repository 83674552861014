import React, { Fragment, useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { handleError } from '@helpers/errors';
import { makeGetRequest } from '@helpers/requests';
import { CHECKOUT_RATES } from '@helpers/api';
import { Col, Row, Divider } from '@components/Grid';
import Loader from '@components/Loader';
import styles from './styles.module.scss';
import Button from '@components/Button';
import Alert from '@components/Alert';
import Choices from '@components/Choices';

const Shipping = ({ addresses, currency, websiteId, basketId, onContinue, onPrevious }) => {
    const [loading, setLoading] = useState(true);
    const [rates, setRates] = useState([]);

    const acceptedRates = [
        'UPS® Ground',
        'USPS Priority Mail - Package',
        'UPS Worldwide Expedited®',
        'UPS Worldwide Saver®',
        'USPS Priority Mail Intl - Package',
        'USPS Priority Mail Express Intl - Package',
    ];

    useEffect(() => {
        (async () => {
            try {
                const { data: ratesData } = await makeGetRequest(CHECKOUT_RATES(basketId), {
                    websiteId,
                });
                setRates(
                    ratesData.rates.filter(
                        ({ serviceName }) => acceptedRates.indexOf(serviceName) > -1
                    )
                );
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        })();
    }, []);

    const customRates = [
        {
            serviceCode: 'merlin_standard',
            serviceName: 'Standard Shipping',
            shipmentCost: 10,
            otherCost: 0,
        },
        {
            serviceCode: 'merlin_fast',
            serviceName: 'Express Shipping',
            shipmentCost: 15,
            otherCost: 0,
        },
    ];

    if (loading) return <Loader height={400} />;

    return (
        <Fragment>
            <h1>Shipping</h1>
            <p>Select a shipping service.</p>

            <Formik
                initialValues={{
                    service: rates.length === 1 ? rates[0]?.serviceName : undefined,
                }}
                validationSchema={Yup.object().shape({
                    service: Yup.string().required('You must select a delivery service'),
                })}
                onSubmit={(values, actions) =>
                    onContinue({
                        values: {
                            service: !rates.length
                                ? customRates.find((rate) => values.service === rate.serviceName)
                                : rates.find((rate) => values.service === rate.serviceName),
                        },
                        actions,
                    })
                }
            >
                {({ values, errors, touched, handleSubmit, isSubmitting, setFieldValue }) => (
                    <Fragment>
                        {!rates.length && (
                            <Fragment>
                                {addresses?.shipping?.country === 'US' ? (
                                    <Choices
                                        radio
                                        onChange={({ id }) => setFieldValue('service', id)}
                                        fullWidth
                                    >
                                        {customRates.map((rate) => (
                                            <Choices.Choice
                                                key={rate.serviceName}
                                                title={rate.serviceName}
                                                checked={values.service}
                                            >
                                                <Choices.Choice.Content>
                                                    <p>
                                                        <b>{`${currency.symbol}${rate.shipmentCost}`}</b>
                                                    </p>
                                                </Choices.Choice.Content>
                                            </Choices.Choice>
                                        ))}
                                    </Choices>
                                ) : (
                                    <div className={styles.shippingNoDelivery}>
                                        <p>
                                            We're sorry, we can't currently deliver to your
                                            location, one of our sales team will be in touch!
                                        </p>
                                        <Divider />
                                        <Button link="/" text="Go Home" />
                                    </div>
                                )}
                            </Fragment>
                        )}

                        {!!rates.length && (
                            <Choices
                                radio
                                onChange={({ id }) => setFieldValue('service', id)}
                                fullWidth
                            >
                                {rates.map(
                                    ({ serviceName: name, shipmentCost, otherCost }, index) => (
                                        <Choices.Choice
                                            key={name}
                                            title={name}
                                            checked={values.service}
                                        >
                                            <Choices.Choice.Content>
                                                <p>
                                                    <b>{`${currency.symbol}${(
                                                        shipmentCost + otherCost
                                                    ).toFixed(2)}`}</b>
                                                </p>
                                            </Choices.Choice.Content>
                                        </Choices.Choice>
                                    )
                                )}
                            </Choices>
                        )}

                        {errors.service && touched.service && (
                            <Fragment>
                                <Divider margin={2} />
                                <Alert message={errors.service} type="error" />
                            </Fragment>
                        )}

                        <Divider margin={4} />
                        {(!!rates.length || addresses?.shipping?.country === 'US') && (
                            <Row between="xs">
                                <Col>
                                    <Button text="Back" secondary onClick={onPrevious} />
                                </Col>
                                <Col>
                                    <Button
                                        text="Next"
                                        onClick={handleSubmit}
                                        submitting={isSubmitting}
                                    />
                                </Col>
                            </Row>
                        )}
                    </Fragment>
                )}
            </Formik>
        </Fragment>
    );
};

export default Shipping;
